import React from "react";

import {
	useGetUpgradePackagesQuery,
	useSetPackageIdPrePaymentMutation,
} from "../../store/apiSlices/upgradePackagesApi";
import CircularLoading from "../../HelperComponents/CircularLoading";

import "./Packages.css";
import { useNavigate } from "react-router-dom";
import PackagesFeatures from "./PackagesFeatures";
import { toast } from "react-toastify";

export const PackagesPlans = () => {
	const navigate = useNavigate();
	const { data: upgradePackages, isLoading } = useGetUpgradePackagesQuery();

	const packagesIsNotActive = upgradePackages?.every?.(
		(pack) => pack?.status === "غير نشط"
	);

	// Find the package with the highest yearly_price
	const highestPricedPackage = upgradePackages?.reduce((max, item) => {
		// Calculate the price considering the discount
		const priceWithDiscount =
			item.discount > 0 ? item.yearly_price - item.discount : item.yearly_price;

		// Determine if the current item should be the new max
		return priceWithDiscount >
			(max
				? max.discount > 0
					? max.yearly_price - max.discount
					: max.yearly_price
				: 0)
			? item
			: max;
	}, null);

	// handle set package id and navigate user to checkout package
	const [setPackageIdPrePayment] = useSetPackageIdPrePaymentMutation();
	const handleNavigateToCheckoutPackages = async (pack) => {
		// data that send to api...
		let formData = new FormData();
		formData.append("package_id", pack?.id);

		// make request...
		try {
			const response = await setPackageIdPrePayment({
				body: formData,
			});

			// Handle response
			if (
				response.data?.success === true &&
				response.data?.data?.status === 200
			) {
				navigate("/checkout-packages");
			} else {
				// Handle display errors using toast notifications
				toast.error(
					response?.data?.message?.ar
						? response.data.message.ar
						: response.data.message.en,
					{
						theme: "light",
					}
				);
			}
		} catch (error) {
			console.error("Error changing setPackageIdPrePayment:", error);
		}
	};

	return (
		<div>
			<div className='package-boxes pb-5 d-flex flex-md-row flex-column gap-4 align-items-center justify-content-center flex-wrap'>
				{isLoading ? (
					<div className='w-100 d-flex flex-column align-items-center justify-content-center'>
						<CircularLoading />
					</div>
				) : !upgradePackages?.length || packagesIsNotActive ? (
					<h3 className=' w-100 d-flex justify-content-center align-content-center mt-5'>
						لا توجد باقات!
					</h3>
				) : (
					upgradePackages?.map((item, idx) => (
						<div
							key={idx}
							className={`p-4 content-package ${
								item === highestPricedPackage ? "top-package" : ""
							}`}>
							<div className='w-100'>
								<h2 className='d-flex align-items-center  text-center gap-4 mb-6 justify-content-center pack-name'>
									{item?.name}
								</h2>
								<h2 className='gap-1 d-flex justify-content-center align-items-baseline pack-price-box'>
									{item?.discount > 0 ? (
										<>
											<span className='price'>
												{item?.yearly_price - item?.discount}
												<span className='currency pe-1'>ر.س</span>
											</span>
											<span className='discount-price'>
												{item?.yearly_price}
												<span className='currency'>ر.س</span>
											</span>
										</>
									) : (
										<span className='price'>
											{item?.yearly_price}{" "}
											<span className='currency '>ر.س</span>
										</span>
									)}
									<p className='package-type '>/سنوياََ</p>
								</h2>
								<div>
									<PackagesFeatures packageFeatures={item?.plans || []} />
								</div>
							</div>

							{item?.is_selected && item?.package_paid ? (
								<div className='w-100 d-flex justify-content-center align-items-center current_package_btn'>
									الباقة الحالية
								</div>
							) : (
								<button
									className='package_btn'
									onClick={() => {
										handleNavigateToCheckoutPackages(item);
									}}>
									إختر الباقة
								</button>
							)}
						</div>
					))
				)}
			</div>
		</div>
	);
};

export default PackagesPlans;
